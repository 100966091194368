<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
    >
      <transition mode="out-in">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-card>
              <b-form @submit.prevent="save">
                <b-row>

                  <!-- Name -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Name')"
                      label-for="v-name"
                    >
                      <b-form-input
                        id="v-name"
                        v-model="payload.data.name"
                        :placeholder="$t('Name')"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Phone -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Email')"
                      label-for="v-seo-name"
                    >
                      <b-form-input
                        id="v-email"
                        v-model="payload.data.email"
                        type="email"
                        :placeholder="$t('Email')"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Email -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Mobile')"
                      label-for="v-sort"
                    >
                      <b-form-input
                        id="v-mobile"
                        v-model="payload.data.mobile"
                        type="tel"
                        :placeholder="$t('Mobile')"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Commission -->
                  <!-- b-col cols="12">
                    <b-form-group
                      :label="$t('Commission')"
                      label-for="v-sort"
                    >
                      <b-form-input
                        id="v-commotion"
                        v-model="payload.data.commission"
                        :placeholder="$t('Commission')"
                        required
                      />
                    </b-form-group>
                  </b-col-->

                  <b-col cols="12">
                    <b-form-group
                      :label="$t('iban')"
                      label-for="v-sort"
                    >
                      <b-form-input
                        id="v-iban"
                        v-model="payload.data.iban"
                        :placeholder="$t('iban')"
                        required
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Is Active -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('Is Active ?')"
                      label-for="v-is-active"
                    >
                      <b-form-checkbox
                        id="v-is-active"
                        v-model="payload.data.is_active"
                        :checked="payload.data.is_active"
                        class="custom-control-primary"
                        name="is_active"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- Submit -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      <template>
                        <span v-if="!payload.isLoading">{{ $t('Save') }}</span>
                        <b-spinner
                          v-else
                          small
                        />
                      </template>
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </b-card>
          </b-col>
        </b-row>
      </transition>
    </b-overlay>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'

export default {
  name: 'AffiliateMarketingCreate',
  directives: {
    Ripple,
  },
  data() {
    return {
      payload: {
        isLoading: false,
        data: {
          name: '',
          email: '',
          mobile: '',
          // commission: '',
          iban: '',
          is_active: false,
        },
      },
    }
  },
  methods: {
    save() {
      this.payload.isLoading = true

      useJwt.post('/customers', this.payload.data)
        .then(({ data }) => {
          this.$root.showToast('success', data.message, data.message)
          this.$router.push({ name: 'affiliate-marketing' })
        })
        .catch(({ response }) => {
          let errorMsg = ''

          if (response.status === 422) {
            Object.keys(response.data.errors).forEach(key => {
              errorMsg += `${key}: ${response.data.errors[key].join('| ')}`
            })
          }

          if (response.data.message.code !== undefined) {
            errorMsg += response.data.message.code
          }

          this.$root.showAlert('error', errorMsg)
        }).finally(() => {
          this.payload.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
</style>
